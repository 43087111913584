<template>
  <div class="loan-scheme">
    <div class="loan-select">
      <div class="list">
        <div v-for="item in items" :key="item.id" class="item" :class="{ select: active == item.id }" @click="goSelect(item)">{{ item.text }}<van-icon v-if="item.checked" name="checked" /></div>
      </div>
      <div class="main">
        <div class="header">
          <div class="head">
            <div class="left">
              <div class="icon"><img :src="imgIcon" alt=""></div>
              <div class="title">御猫1号产品</div>
              <div class="type">信用贷</div>
            </div>
            <div class="right">
              <van-checkbox v-model="activeItem.checked" checked-color="#ff7700" />
            </div>
          </div>
          <div class="cont">
            <div class="it">
              <div class="tt">额度范围</div>
              <div class="ct">50~100万</div>
            </div>
            <div class="it">
              <div class="tt">月利率</div>
              <div class="ct">0.5~0.7%</div>
            </div>
            <div class="it">
              <div class="tt">贷款期限</div>
              <div class="ct">12~36个月</div>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="title"><span />还款方式</div>
          <div class="cont">
            等本等息，等额本息
          </div>
          <div class="title"><span />利率说明</div>
          <div class="cont">
            <div class="group">
              <div class="tt">贷款期限(月)</div>
              <div class="ct red">12-36</div>
            </div>
            <div class="group">
              <div class="tt">月利率(%)</div>
              <div class="ct blue">0.5-0.75</div>
            </div>
          </div>
          <div class="title"><span />须知</div>
          <div class="cont">
            1. 平台服务费为贷款总额度的百分之三。<br>
            2.该费用为平台服务费，线下不允许再收取其它费用，如有违反，后果自负。<br>
            3. 根据实际情况显示。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import imgIcon from '@/assets/logo@2x.png'
export default {
  data() {
    return {
      imgIcon,
      active: 0,
      checked: false,
      activeItem: {},
      items: [{ text: '方案5', id: 0, checked: true }, { text: '方案4', id: 1 }, { text: '方案3', id: 2 }, { text: '方案2', id: 3 }, { text: '方案1', id: 4 }]
    }
  },
  methods: {
    goSelect(item) {
      this.active = item.id
    }
  }
}
</script>
<style lang="scss" scoped>
  .loan-scheme {
    height: 100%;
    .loan-select {
      display: flex;
      height: 100%;
      background: #fcfcfc;
      .list {
        width: 84px;
        background: #f3f3f5;
        .item {
          width: 84px;
          padding: 14px 0;
          text-align: center;
          &.select {
            background: #fff;
          }
        }
      }
      .main {
        flex: 1;
        padding: 16px;
        .header {
          padding: 10px;
          box-shadow: 0px 3px 6px rgba(0,0,0,0.06);
          border-radius: 8px;
          .head {
            display: flex;
            align-items: center;
            padding: 10px 0;
            .left {
              display: flex;
              flex: 1;
              align-items: center;
              .icon {
                width: 27px;
              }
              .title {
                padding-left: 10px;
                font-size: 14px;
                color: #2E3135;
              }
              .type {
                padding: 5px 10px;
                margin-left: 10px;
                font-size: 12px;
                color: #18C47C;
                background: #DCF6EB;
                border-radius: 4px;;
              }
            }
          }
          .cont {
            padding: 10px 16px;
            border-radius: 4px;
            background: #fff3e8;
            .it {
              display: flex;
              align-items: center;
              .tt {
                padding: 5px 0;
                flex: 1;
                font-size: 14px;
                color: #999999;
              }
            }
          }
        }
        .content {
          .title {
            display: flex;
            align-items: center;
            padding: 16px 0 10px 0;
            span {
              display: block;
              width: 3px;
              height: 12px;
              margin-right: 10px;
              background: #FF7700;
            }
          }
          .cont {
            padding: 12px 29px;
            margin: 0 -16px;
            font-size: 14px;
            line-height: 24px;
            background: #fff;
            .group {
              display: flex;
              align-items: center;
              padding: 5px 0;
              .tt {
                flex: 1;
              }
              .ct {
                &.red {
                  color: #F86767;
                }
                &.blue {
                  color: #2697FF;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
